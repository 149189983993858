import React, { useEffect, useState } from 'react';

const OpenReplay = () => {
    const [tracker, setTracker] = useState(null)

    useEffect(() => {
        (async function () {
            const Tracker = await import("@openreplay/tracker");
            const openReplayTracker = new Tracker.default({
                projectKey: process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY,
                ingestPoint: process.env.NEXT_PUBLIC_OPENREPLAY_INGEST_POINT,
                __DISABLE_SECURE_MODE: process.env.NEXT_PUBLIC_OPENREPLAY_DISABLE_SECURE_MODE,
            });

            setTracker(openReplayTracker);
        })();
    }, []);

    useEffect(() => {
        tracker && tracker.start()
    }, [tracker]);
};

export default OpenReplay;
